import { FC } from "react";
import { View } from "react-native";
import { availableFilters } from "./filters";
import { TableDropdown } from "../tables/table-dropdown";

const bulkItems = [
  { id: "Publish", content: "Publish" },
  { id: "Draft", content: "Move to Draft" },
  { id: "Delete", content: "Move to Trash" },
  { id: "Archive", content: "Archive" },
];

const usersBulkItems = [
  { id: "Active", content: "Set Active" },
  { id: "Suspend", content: "Suspend" },
  { id: "Delete", content: "Delete" },
];

interface IGridFilters {
  activeFilters: string[];
  handleBulkAction: (id: string) => void;
  selectedResources: any[];
  isUsersTable: boolean;
}

export const GridFilters: FC<IGridFilters> = ({
  activeFilters,
  handleBulkAction,
  selectedResources,
  isUsersTable,
}) => {
  const renderFilter = (filterName: string) => {
    const filter = availableFilters.find(({ name }) => name === filterName);

    return filter?.component ? (
      <View
        // @ts-ignore
        style={{ display: "contents", maxWidth: 150, minWidth: 120 }}
        key={filterName}
      >
        {filter?.component}
      </View>
    ) : null;
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: 8,
        flex: 1,
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {activeFilters.map((activeFilter) => renderFilter(activeFilter))}
      <TableDropdown
        placeholder="Bulk Actions"
        disabled={selectedResources.length <= 0}
        items={isUsersTable ? usersBulkItems : bulkItems}
        onSelectItem={(id) => handleBulkAction(id)}
      />
    </View>
  );
};
